import React,{useEffect, useState} from 'react';
import { AppBar, Toolbar, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink} from 'react-router-dom';
import logo from '../../../../assets/logobianco.png';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    navDisplayFlex: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `white`
    },
    linkLogo: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `white`,
      marginRight:`5%`
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    }
  }));

const Navbar = () => {
    const classes = useStyles(),
    [navbar, setNavbar] = useState(),
    navLinks = [
        { title: `Acceso`, path: `/acceso` },
      ];
   

    useEffect(()=>{
      setNavbar(<AppBar position="static">
      <Toolbar>
      
          <List component="nav" 
          aria-labelledby="main navigation"
          className = {classes.navDisplayFlex}
          >
            <NavLink className={classes.linkLogo} to = {'/'} >
              <img src={logo} alt="blanco.es logo" height="40px"/>
              </NavLink>
              {navLinks.map(({ title, path }) => (
                    <NavLink className={classes.linkText} to = {path} >
                      <ListItem button>
                        <ListItemText primary={title} />
                      </ListItem>
                    </NavLink>
                  
              ))}
          </List>
      </Toolbar>
  </AppBar>);

    },[]);

  return (<React.Fragment>{navbar}</React.Fragment>);
} ;

export default Navbar;