import React from 'react';
import Header from './Header/Header';
import MainContent from './MainContent/MainContent';

const Layout = () => (
    <React.Fragment>
        <Header />
        <MainContent />
    </React.Fragment>
);
export default Layout;